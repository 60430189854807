import React, { Component } from 'react';

class MainFooter extends Component {
    constructor(props) {
        super(props);
        this.state={
            copyRightYear: ''
        }
    }    

    componentDidMount(){
        var current_year=new Date().getFullYear();
        this.setState({
            copyRightYear: current_year
        })
    }

    /* fbLinkClick = (e) => {
        e.preventDefault();
        var url = 'https://www.facebook.com/profile.php?id=100090982122591';
        window.open(url, '_blank');

    } */

    render() {
        return (
            <>
                <footer>
                    <div className="footer bottom_cross1">                        
                        <div className="copyright">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <p>© {this.state.copyRightYear} All Rights Reserved. Design by<a href="https://www.s2pservices.co.uk/" target='__blank'> www.s2pservices.co.uk</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </>
        );
    }
}

export default MainFooter;