import React, { Component } from 'react';
import whychooseusImage from '../../assets/images/whychooseus.jpg';
import aboutImage from '../../assets/images/about.png';
import howtobookImage from '../../assets/images/howtobook.jpg';

class Terms extends Component {
    render() {
        return (
            <>
                <section className="section_all">
                    <div className="container">
                        <div className="row vertical_content_manage mt-5">
                            <div className="col-lg-12">
                                <div className="titlepage">
                                    <h2>Booking Terms & Conditions?</h2><br />
                                </div>
                                <p>
                                    We are MyBoogy Airport Transfer, a company registered in England and Wales whose registered address is located at, 200 Brook Drive, Reading, England, RG2 6UB ("MAT", "we", “our” or "us").
                                    We provide airport transfer services to/from All major UK Airport ( Heathrow, Gatwick, Stansted, City, Luton, Birmingham, Liverpool, Manchester, Bristol,  Edinburgh, Glasgow & Newcastle)
                                    to UK Postcode destination.
                                </p>
                            </div>
                        </div>

                        <div className="row vertical_content_manage mt-5">
                            <div className="col-lg-4">
                                <div className="img_about mt-3">
                                    <img src={aboutImage} alt="..." className="img-fluid mx-auto d-block" />
                                </div>
                            </div>
                            <div className="col-lg-8">
                                <p>
                                    <div className="titlepage">
                                        <h3>Definitions</h3>
                                    </div>
                                    <strong>Meet & Greet </strong>
                                    is a service that can be opted for by clients, while booking the journey online. Once opted for this service, driver holds a name board ( Electronic soft copy/ Placard) for the lead passenger,
                                    at the airport departure gate.
                                </p>
                                <br />
                                <p>
                                    <strong>Requested Pickup time</strong><br />
                                    Once the flight has landed, there will be a formality for checking passport control and baggage claiming.<br />
                                    MAT, requests customers to understand the approximate clearing times from customs. Usually this clearance activity takes a minimum of 30 mins for domestic flights and 45 minutes for international flights.
                                </p>
                                <br />
                                <p>
                                    <strong>Waiting time</strong><br />
                                    Waiting time is calculated after requested pickup time mentioned in the booking form at the time of online booking.
                                </p>
                                <br />
                                <p>
                                    <strong>Additional Charges</strong><br />
                                    We impose the cost of Airport parking fees after 60-minutes free waiting period expires. Clause 6 of these terms and conditions defines these costs.
                                </p>
                            </div>
                        </div>
                        <div className="row vertical_content_manage">
                            <div className="col-lg-8">
                                <p className="text-muted mt-3">
                                    <div className="titlepage">
                                        <h3>Booking Terms</h3>
                                    </div>
                                    <ol className='list-style'>
                                        <li>The website shows FIXED & All-Inclusive Prices (apart from parking charges).</li>
                                        <li>Meet & Greet is not included in the best price/optional. (Our driver will hold the passenger's name board by arrival barriers) 60 Minutes waiting time is included in the charges.</li>
                                        <li>All taxes and road tolls are included in the quotation.</li>
                                        <li>MAT does not charge anything extra for flight delays, since we do live flight tracking.</li>
                                        <li>We use real-time airline data to track the flight landing times.</li>
                                        <li>
                                            After the requested pickup time, and FREE 60 minutes waiting time ends, we reflect the cost of  parking to the customer as an additional charge. Charges are as below.
                                            <table className='table table-bordered'>
                                                <thead>
                                                    <tr>
                                                        <th>Duration</th>
                                                        <th>Charge</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>0 - 15 minutes</td>
                                                        <td>£10.00</td>
                                                    </tr>
                                                    <tr>
                                                        <td>15 - 30 minutes</td>
                                                        <td>£15.00</td>
                                                    </tr>
                                                    <tr>
                                                        <td>30 - 45 minutes</td>
                                                        <td>£20.00</td>
                                                    </tr>
                                                    <tr>
                                                        <td>45 - 60 minutes</td>
                                                        <td>£25.00</td>
                                                    </tr>
                                                    <tr>
                                                        <td>60 - 90 minutes</td>
                                                        <td>£35.00</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </li>
                                        <li>In case you have not met with the driver, please call helpline number ( 0331 630 4110 ) immediately, if you leave the terminal without calling, we will register the booking as a no-show and charge the client in full as per the law of the land.</li>
                                        <li>For Airport drop, we allow 15 minutes of maximum free waiting time at the pick-up point. The additional charge is £5 per 15 minutes and fraction thereof.</li>
                                        <li>
                                            MAT cannot be held responsible for any late arrival to a destination, namely airport, due to traffic. Hence we will not accept any responsibility for missed flights.
                                            MAT shall be under no liability to the Customer whatsoever for any direct or indirect loss and/or expense (including loss of profit) suffered by the Customer arising out of a breach by the Company of these terms and conditions.
                                            In the event of any claim against MAT arising out of its performance of hire, the Company’s liability shall be limited to a refund not exceeding the cost of the journey. Any other payments will be entirely at the discretion of the Company.
                                        </li>
                                        <li>In case of MAT vehicle going through any mechanical failure on the transit we will ensure to give a replacement vehicle as soon as possible/ customer can arrange another vehicle.</li>
                                        <li>Any kind of rerouting while the journey is on is going to incur additional charges. Also, MAT will not take any responsibility for the delay to reach the destination under the same situation.</li>
                                        <li>MAT accepts no responsibility for any loss or damage to property/ luggage/ fragile items, howsoever such loss or damage may be caused. In the event of property being left in a vehicle, we will gladly organize the return of such items if you cover the postage and packaging costs. Any vomiting, spilling liquid or food to the vehicle, MAT will charge £80.00 for valeting.</li>
                                        <li>MAT reserves the right to charge £15 supplement to the passengers, in the event, where the passengers arrive with excess luggage than mentioned on the booking and where this luggage will need to be placed inside the vehicle (at driver discretion and consent).
                                        </li>
                                    </ol>
                                </p>
                            </div>
                            <div className="col-lg-4">
                                <div className="img_about mt-5">
                                    <img src={howtobookImage} alt="..." className="img-fluid mx-auto d-block" />
                                </div>
                            </div>
                        </div>
                        <div className="row vertical_content_manage">
                            <div className="col-lg-4">
                                <div className="img_about mt-4">
                                    <img src={whychooseusImage} alt="..." className="img-fluid mx-auto d-block" />
                                </div>
                            </div>
                            <div className="col-lg-8">
                                <p className="text-muted mt-3">
                                    <div className="titlepage">
                                        <h3>Child Seats</h3>
                                    </div>
                                    <p>Passenger will need to select the number of child seats at the time of booking. MAT will provide child seats as per the same. Child Seats are chargeable.</p>
                                </p>
                                <p className="text-muted mt-3">
                                    <div className="titlepage">
                                        <h3>Booking Cancellation Charges</h3>
                                    </div>
                                    <p className='mt-1'>FREE cancellation if, cancellation made more than 12 hours before the transfer time; MAT will only charge 2.5% of the fare as merchant processing fee. Refunds may take upto 5 to 7 working days.</p>
                                    <p className='mt-1'>50% cancellation charge if cancellation made between 6 and 12 hours before the reporting time.</p>
                                    <p className='mt-1'>100% cancellation charge if cancellation made within 6 hours of delivery time. </p>
                                    <p className='mt-1'>For cancellation please dial the number : ( 0331 630 4110 )</p>
                                </p>
                                <p className="text-muted mt-3">
                                    <div className="titlepage">
                                        <h3>No-Show Charges</h3>
                                    </div>
                                    <p className='mt-1'>If customer does not show up for a ride, we will apply the booking as no-show and will charge the full amount of booking. Waiting times and pickup rules are listed in clause 7 of this Terms and Conditions.</p>
                                </p>
                                <p className="text-muted mt-3">
                                    <div className="titlepage">
                                        <h3>Payments</h3>
                                    </div>
                                    <p className='mt-1'>
                                        MyBoogy Airport Transfer accepts<br />
                                        <ul>
                                            <li>Cash</li>
                                            <li>Credit card</li>
                                            <li>PayPal</li>
                                            <li>Apple Pay</li>
                                            <li>Google Pay</li>
                                        </ul>
                                    </p>
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default Terms;