import React, { Component } from 'react';
import { Redirect } from 'react-router';
import axios from "axios";
import ApiURL from "../../api/ApiURL";
import cogoToast from 'cogo-toast';
import LocalStorageHelper from '../../LocalStorageHelper/LocalStorageHelper';
import loginImage from '../../assets/images/login.jpg';
import validation from '../../validation/validation';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            btnSignUp: "Save",
            name: '',
            mobileCode: '+44',
            mobile: '',
            loginEmail: '',
            loginPwd: '',
            regEmail: '',
            regPwd: '',
            confirmPwd: '',
            countryCodes: [],
            redirectToQuote: false,
        }
    }

    componentDidMount() {
        this.getCountryCodes();
    }

    getCountryCodes = () => {
        axios.get(ApiURL.GetAllCountryCode).then(response => {
            let respData = response.data;
            if (respData.StatusCode === 200) {
                let dataResult = respData.DataResult;
                if (dataResult.length > 0) {
                    this.setState({
                        ...this.state,
                        countryCodes: dataResult
                    })
                }
            }
            else if (respData.StatusCode === 500) {
                cogoToast.error("Internal Server Error ! Please Try Again.", { position: 'bottom-center' });
            }

        }).catch(error => {
            cogoToast.error("Request Failed! Please Try Again.", { position: 'bottom-center' })
        })
    }

    onChangeHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    signIn = (e) => {
        e.preventDefault();
        var param = {
            Email: this.state.loginEmail,
            Pwd: this.state.loginPwd
        };
        axios.post(ApiURL.UserLogin, param).then(response => {
            let respData = response.data;
            if (respData.StatusCode === 200) {
                //cogoToast.success('User Login Succeeded!', { position: 'bottom-center' });
                var userData = respData.DataResult;
                console.log(userData);
                LocalStorageHelper.setUser(JSON.stringify(userData));
                this.setState({ redirectToQuote: true })
            }
            else if (respData.StatusCode === 400) {
                cogoToast.error(respData.Message, { position: 'bottom-center' });
            }
            else if (respData.StatusCode === 500) {
                cogoToast.error("Internal Server Error ! Please Try Again.", { position: 'bottom-center' });
            }

        }).catch(error => {
            cogoToast.error("Login Request Failed! Please Try Again.", { position: 'bottom-center' })
        })
    }

    signUp = (e) => {
        e.preventDefault();
        let fullName = this.state.name;
        let mobileCode= this.state.mobileCode;
        let mobile = this.state.mobile;
        let email = this.state.regEmail;
        let pwd = this.state.regPwd;
        let confPwd = this.state.confirmPwd;
        let appType = 'W';

        if (fullName.length === 0) {
            cogoToast.error("Full Name Required.", { position: 'bottom-center' });
        }
        else if (mobile.length === 0) {
            cogoToast.error("Mobile Number Required.", { position: 'bottom-center' });
        }
        else if (mobile.length !== 0 && !(validation.MobileRegx).test(mobile)) {
            cogoToast.error("Invalid Mobile No.", { position: 'bottom-center' });
        }
        else if (email.length === 0) {
            cogoToast.error("Email Required!", { position: 'bottom-center' });
        }
        else if (email.length > 0 && !(validation.EmailRegx).test(email)) {
            cogoToast.error("Invalid Email.", { position: 'bottom-center' });
        }
        else if (pwd.length > 0 && confPwd.length > 0 && pwd !== confPwd) {
            cogoToast.error("Password confirmation did not match!", { position: 'bottom-center' });
        }
        else {
            this.setState({ btnSignUp: "Processing.." });
            var param = {
                FullName: fullName,
                MobileCode: mobileCode,
                Mobile: mobile,
                Email: email,
                Pwd: pwd,
                ApplicationType: appType
            };
            axios.post(ApiURL.UserRegistration, param).then(response => {
                let respData = response.data;
                if (respData.StatusCode === 200) {
                    cogoToast.success('User Registration Succeeded!', { position: 'bottom-center' });
                    var userData = respData.DataResult;
                    //console.log(userData);
                    LocalStorageHelper.setUser(JSON.stringify(userData));
                    this.setState({ redirectToQuote: true, btnSignUp: "Submit" })
                }
                else if (respData.StatusCode === 400) {
                    cogoToast.error(respData.Message, { position: 'bottom-center' });
                    this.setState({ btnSignUp: "Submit" })
                }
                else if (respData.StatusCode === 500) {
                    cogoToast.error("Internal Server Error ! Please Try Again.", { position: 'bottom-center' });
                    this.setState({ btnSignUp: "Submit" })
                }

            }).catch(error => {
                cogoToast.error("User Registration Failed! Please Try Again.", { position: 'bottom-center' })
                this.setState({ btnSignUp: "Submit" })
            })
        }
    }

    pageRedirectToQuote = () => {        
        if (this.state.redirectToQuote === true) {
            return <Redirect to="/quote" />
        }
    }

    render() {
        return (
            <>
                <section className='payment-bg'>
                    <div className="container">
                        <div className="min-vh-100 d-flex align-items-center">
                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-10 mx-auto">
                                        <div className="bg-white">
                                            <div className="d-flex align-items-center">
                                                <div className="d-none d-md-block d-lg-block">
                                                    <img src={loginImage} alt="..." />
                                                </div>
                                                <div className="col-md-5 col-12 col-lg-5">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="boxLogin">
                                                                <h3>Book Online</h3>
                                                                <form>
                                                                    <h4>Existing Customer</h4>
                                                                    <div className="inputBox">
                                                                        <input type="text" name="loginEmail" required value={this.state.loginEmail} onChange={this.onChangeHandler} autoComplete={0} />
                                                                        <label><i className="fa fa-envelope"></i> Email Address</label>
                                                                    </div>
                                                                    <div className="inputBox">
                                                                        <input type="password" name="loginPwd" required value={this.state.loginPwd} onChange={this.onChangeHandler} />
                                                                        <label><i className="fa fa-eye"></i> Password</label>
                                                                    </div>
                                                                    <button className="btn btn-warning btn-sm" onClick={this.signIn}>
                                                                        <i className="fa fa-sign-in"></i> Login
                                                                    </button>&nbsp;
                                                                    <button className="btn btn-light btn-sm">
                                                                        <i className="fa fa-refresh"></i> Reset
                                                                    </button>
                                                                    &nbsp;                                                                    


                                                                    <h4>New Customer</h4>
                                                                    <div className="inputBox">
                                                                        <input type="text" name="name" required value={this.state.name} onChange={this.onChangeHandler} autoComplete={0} />
                                                                        <label><i className="fa fa-user"></i> Name *</label>
                                                                    </div>
                                                                    <div className="input-group my-3">                                                                        
                                                                        <select
                                                                            name="mobileCode"
                                                                            className="btn btn-outline-light rounded-0 px-1 borderSelect"
                                                                            value={this.state.mobileCode}
                                                                            onChange={this.onChangeHandler}>
                                                                            {
                                                                                this.state.countryCodes.map((cc, i) => {
                                                                                    return (
                                                                                        <option key={i} value={cc.MobileCode}>
                                                                                            {cc.MobileCode}
                                                                                        </option>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </select>
                                                                        <input
                                                                            type="text"
                                                                            name="mobile"
                                                                            className="form-control countryCode"
                                                                            value={this.state.mobile}
                                                                            onChange={this.onChangeHandler}
                                                                            autoComplete={0}
                                                                        />
                                                                    </div>
                                                                    <div className="inputBox">
                                                                        <input type="text" name="regEmail" required value={this.state.regEmail} onChange={this.onChangeHandler} autoComplete={0} />
                                                                        <label><i className="fa fa-envelope"></i> Email *</label>
                                                                    </div>
                                                                    <div className="inputBox">
                                                                        <input type="password" name="regPwd" required value={this.state.regPwd} onChange={this.onChangeHandler} />
                                                                        <label><i className="fa fa-eye"></i> Password *</label>
                                                                    </div>
                                                                    <div className="inputBox">
                                                                        <input type="password" name="confirmPwd" required value={this.state.confirmPwd} onChange={this.onChangeHandler} />
                                                                        <label><i className="fa fa-eye"></i> Confirm Password *</label>
                                                                    </div>
                                                                    <button className="btn btn-warning btn-sm" onClick={this.signUp}>
                                                                        <i className="fa fa-save"></i> {this.state.btnSignUp}
                                                                    </button>
                                                                    &nbsp;
                                                                    <button className="btn btn-light btn-sm">
                                                                        <i className="fa fa-refresh"></i> Reset
                                                                    </button>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div >
                    {this.pageRedirectToQuote()}
                </section >
            </>
        );
    }
}

export default Login;