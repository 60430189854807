import React, { Component } from 'react';
import MainHeader from '../components/common/MainHeader';
import MainFooter from '../components/common/MainFooter';
import PaymentDetail from '../components/payment/PaymentDetail';

class PaymentDetailPage extends Component {
    render() {
        return (
            <>
                <MainHeader />
                
                <PaymentDetail />
                
                <MainFooter />
            </>
        );
    }
}

export default PaymentDetailPage;