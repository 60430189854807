import React, { Component } from 'react';
import ApiURL from "../../api/ApiURL";
import axios from "axios";
import cogoToast from 'cogo-toast';
import LocalStorageHelper from '../../LocalStorageHelper/LocalStorageHelper';
import { Redirect } from 'react-router';

class BookingHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: '',
            endDate: '',
            bookingStatusId: 0,
            bookings: [],
            redirectToLogin: false,
            showModal: false,
            selectedBooking: null
        }
    }

    componentDidMount() {
        var self = this;
        /* Get User Profile Data */
        var userData = JSON.parse(LocalStorageHelper.getUser());
        if (userData !== null) {
            this.setState({
                custUserId: userData.UserId,
                userId: userData.UserId,
                email: userData.Email,
                mobile: userData.Mobile
            }, () => {
                self.getBookingHistoryData();
            })


        }
        else {
            /* TODO: Redirect to the Login Page */
            self.setState({ redirectToLogin: true });
        }
    }

    onChangeHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    getBookingHistoryData = () => {
        var param = {
            CustomerUserId: this.state.custUserId,
            BookingStatusId: this.state.bookingStatusId,
            StartDate: this.state.startDate,
            EndDate: this.state.endDate,
        }
        axios.post(ApiURL.getAllBookings, param).then(response => {
            let respData = response.data;
            if (respData.StatusCode === 200) {
                let dataResult = [];
                if (respData.DataResult.length > 0) {
                    dataResult = respData.DataResult;
                }                
                this.setState({
                    ...this.state,
                    bookings: dataResult
                })
            }
            else if (respData.StatusCode === 500) {
                cogoToast.error("Internal Server Error ! Please Try Again.", { position: 'bottom-center' });
            }

        }).catch(error => {
            cogoToast.error("Request Failed! Please Try Again.", { position: 'bottom-center' })
        })
    }

    showCancelBookingConfirm = (bk) => {
        this.setState({
            selectedBooking: bk,
            showModal: true
        });
    }

    cancelBooking = (e) => {
        e.preventDefault();
        console.log(this.state.selectedBooking);
        var param = {
            BookingNo: this.state.selectedBooking.BookingNo
        };
        axios.post(ApiURL.cancelBooking, param).then(response => {
            let respData = response.data;
            if (respData.StatusCode === 200) {
                let dataResult = respData.DataResult;
                if (dataResult !== null && dataResult > 0) {
                    cogoToast.success("Booking cancelled successfully !", { position: 'bottom-center' });
                    this.setState({
                        ...this.state,
                        selectedBooking: null,
                        showModal: false
                    })
                }
                else {
                    cogoToast.error("Booking cancellation failed ! Please Try Again.", { position: 'bottom-center' });
                    this.setState({
                        ...this.state,
                        selectedBooking: null,
                        showModal: false
                    })
                }
            }
            else if (respData.StatusCode === 500) {
                cogoToast.error("Internal Server Error ! Please Try Again.", { position: 'bottom-center' });
                this.setState({
                    ...this.state,
                    selectedBooking: null,
                    showModal: false
                })
            }

        }).catch(error => {
            cogoToast.error("Request Failed! Please Try Again.", { position: 'bottom-center' })
            this.setState({
                ...this.state,
                selectedBooking: null,
                showModal: false
            })
        })
    }

    pageRedirectToLogin = () => {
        if (this.state.redirectToLogin === true) {
            return <Redirect to="/login" />
        }
    }

    render() {
        var { bookings, showModal } = this.state;
        return (
            <>
                <section className='payment-bg py-3'>
                    <div className="container paymentCard">
                        <div className="row mt-3">
                            <form>
                                <div className="form-row">
                                    <div className="form-group col-md-6 sbsform">
                                        <label htmlFor="startDate">Journey From Date</label>
                                        <input
                                            type='date'
                                            name="startDate"
                                            className='input-sm form-control'
                                            value={this.state.startDate}
                                            onChange={this.onChangeHandler}
                                        />
                                    </div>
                                    <div className="form-group col-md-2 sbsform">
                                        <label htmlFor="endDate">Journey To Date</label>
                                        <input
                                            type='date'
                                            name="endDate"
                                            className='input-sm form-control'
                                            value={this.state.endDate}
                                            onChange={this.onChangeHandler}
                                        />
                                    </div>
                                    <div className="form-group col-md-4 sbsform">
                                        <label htmlFor="bookingStatusId">Choose Status</label>
                                        <select
                                            name="bookingStatusId"
                                            className='input-sm form-control'
                                            value={this.state.bookingStatusId}
                                            onChange={this.onChangeHandler}>
                                            <option value="0">All</option>
                                            <option value="1">Booked</option>
                                            <option value="2">In Progress</option>
                                            <option value="3">Completed</option>
                                            <option value="4">Cancelled</option>
                                        </select>
                                    </div>
                                    <div className="form-group col-md-2 sbsform">
                                        <button type="button" className="btn btn-info" onClick={this.getBookingHistoryData}>Search</button>
                                    </div>
                                </div>
                            </form>                           
                        </div>
                        <div className="row">
                            <div id="no-more-tables">
                                <table className="col-md-12 table-bordered table-striped table-condensed cf">
                                    <thead className="cf">
                                        <tr>
                                            <th>Booking#</th>
                                            <th className="numeric">Booking Date</th>
                                            <th className="numeric">Journey Date</th>
                                            <th className="numeric">Journey From</th>
                                            <th className="numeric">Journey To</th>
                                            <th className="numeric">Is Return</th>
                                            <th className="numeric">Flight#</th>
                                            <th className="numeric">Booking Amt</th>
                                            <th className="numeric">Paid Amt</th>
                                            {/* <th className="numeric">Payment Transaction #</th>    */}
                                            <th className="numeric">Car Name</th>
                                            <th className="numeric">Status</th>
                                            <th className="numeric">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            (bookings !== null && bookings.length > 0) &&
                                            bookings.map((bk, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td data-title="Booking#" className="text-center">{bk.BookingNo}</td>
                                                        <td data-title="Booking Date" className="text-center">{bk.BookingDate}</td>
                                                        <td data-title="Journey Date" className="text-center">{bk.JourneyDate}</td>
                                                        <td data-title="Journey From" className="numeric">{bk.ServiceFrom}</td>
                                                        <td data-title="Journey To" className="numeric">{bk.ServiceTo}</td>
                                                        <td data-title="Is Return" className="numeric text-center">
                                                            <input type="checkbox" className="form-check-input" checked={bk.IsReturn} disabled />
                                                        </td>
                                                        <td data-title="Flight#" className="numeric">{bk.FlightDetails}</td>
                                                        <td data-title="Booking Amount" className="numeric text-end">${bk.BookingAmount}</td>
                                                        <td data-title="Paid Amount" className="numeric text-end">${bk.PaidAmount}</td>
                                                        {/* <td data-title="Payment Transaction #" className="numeric">{bk.PaymentGatewayTransacNo}</td> */}
                                                        <td data-title="Car Name" className="numeric">{bk.CarName}</td>
                                                        <td data-title="Status" className="numeric text-center">{bk.BookingStatus}</td>
                                                        <td data-title="Action" className="numeric text-center">
                                                            <button
                                                                type="button"
                                                                className="btn btn-info"
                                                                title="Cancel Booking"
                                                                disabled={bk.BookingStatus === 'Cancelled' ? 'true' : ''}
                                                                onClick={() => this.showCancelBookingConfirm(bk)}
                                                            >
                                                                <i className="fa fa-trash px-1"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <!-- Modal --> */}
                <div className={showModal === true ? "modal show-modal" : "modal"}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Delete Confirmation</h5>
                                <button type="button" className="btn-close" aria-label="Close" onClick={() => this.setState({ showModal: false })}></button>
                            </div>
                            <div className="modal-body">
                                <span>Do you want to delete the selected record?</span>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-success"
                                    onClick={this.cancelBooking}>Yes
                                </button>

                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    onClick={() => this.setState({ showModal: false })}>No
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default BookingHistory;