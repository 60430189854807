import React, { Component, Fragment } from 'react';
import profileImage from '../../assets/images/profile-img.png';
import { Link } from 'react-router-dom';
import { Redirect } from 'react-router';
/* import * as signalR from '@microsoft/signalr'; */
import LocalStorageHelper from '../../LocalStorageHelper/LocalStorageHelper';

class MainHeader extends Component {
    // Access context using static contextType
    constructor(props) {
        super(props);
        this.state = {
            displayName: '',
            email: '',
            loggedIn: false
        }
        /* let connection = new signalR.HubConnectionBuilder().withUrl(ApiURL.BaseURL + "/notificationsHub", {
            skipNegotiation: true,
            transport: signalR.HttpTransportType.WebSockets
        }).build(); */
    }

    componentDidMount() {
        var userData = JSON.parse(LocalStorageHelper.getUser());
        if (userData !== null) {
            this.setState({
                displayName: userData.DisplayName,
                email: userData.Email,
                loggedIn: true,
                redirectToHome: false
            })
        }
    }

    componentWillUnmount() {
        /* let connection = this.state.SignalRConnection;
        connection.stop().then(() => console.log("Connection stopped successfully!")).catch(err => console.log(err)); */
        //this.setState({ ...this.state, SignalRConnection: null })        
    }

    signOut = () => {
        LocalStorageHelper.removeUser();
        LocalStorageHelper.removeQuoteRequest();
        this.setState({ redirectToHome: true })
    }

    pageRedirectToHome = () => {
        if (this.state.redirectToHome === true) {
            return <Redirect to="/" />
        }
    }

    render() {

        return (
            <Fragment>
                <header>
                    <div className="TopBg px-3 font12 d-none d-sm-block">
                        WhatsApp call : 0331 630 4110,  &nbsp; Emergency call: 07477018889
                        <div className="col-md-2 float-end topMenuSocialIcon">
                            <ul>
                                <li><a href="#"><i className="fa fa-facebook"></i></a></li>
                                <li><a href="#"><i className="fa fa-instagram"></i></a></li>
                                <li><a href="#"><i className="fa fa-twitter"></i></a></li>
                                <li><a href="#"><i className="fa fa fa-linkedin"></i></a></li>
                                <li><a href="#"><i className="fa fa-google-plus"></i></a></li>
                                <li><a href="#"><i className="fa fa-youtube"></i></a></li>
                                <li><a href="#"><i className="fa fa-pinterest"></i></a></li>
                            </ul>
                        </div>
                    </div>

                    <nav className="navbar navbar-expand-lg navbar-dark bg-dark" aria-label="Offcanvas navbar large">
                        <div className="container-fluid">
                            <Link className="navbar-brand" to={"/"}>MyBoogy Airport Transfer</Link>
                            <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar2" aria-controls="offcanvasNavbar2">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="offcanvas offcanvas-end text-bg-dark" tabIndex="-1" aria-labelledby="offcanvasNavbar2Label">
                                <div className="offcanvas-header">
                                    <h5 className="offcanvas-title">MyBoogy Airport Transfer</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                                </div>
                                <div className="offcanvas-body">
                                    <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
                                        <li className="nav-item">
                                            <Link className="nav-link active" to={"/"}>Home</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link active" to={"/"}>Contacts</Link>
                                        </li>

                                        {
                                            (this.state.loggedIn === true) ?
                                                <li className="nav-item dropdown dropstart">
                                                    <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <i className="fa fa-user"></i>
                                                    </a>
                                                    <ul className="list-unstyled">
                                                        <li className="dropdown ml-2">

                                                            <div className="dropdown-menu pb-2" aria-labelledby="dropdownUser">
                                                                <div className="dropdown-item">
                                                                    <div className="d-flex py-2">
                                                                        <div className="avatar avatar-md avatar-online">
                                                                            <img src={profileImage} alt="avatar" className="rounded-circle" />
                                                                        </div>
                                                                        <div className="ml-3 lh-1">
                                                                            <h5 className="px-2 py-0">{this.state.displayName}</h5>
                                                                            <p className="px-2 py-0">{this.state.email}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="dropdown-divider"></div>
                                                                <div>
                                                                    <ul className="list-unstyled">
                                                                        <li>
                                                                            <Link className="dropdown-item" to={"/"}>
                                                                                <span className="mr-1">
                                                                                    <i className="fa fa-user"></i>
                                                                                </span> Profile
                                                                            </Link>
                                                                        </li>
                                                                        <li>
                                                                            <Link className="dropdown-item" to={"/booking-history"}>
                                                                                <span className="mr-2"><i className="fa fa-star"></i></span> Booking History
                                                                            </Link>
                                                                        </li>
                                                                        <li>
                                                                            <Link className="dropdown-item" to={"/"}>
                                                                                <span className="mr-2"><i className="fa fa-cog"></i></span> Settings
                                                                            </Link>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <div className="dropdown-divider"></div>
                                                                <ul className="list-unstyled">
                                                                    <li onClick={this.signOut}>
                                                                        <Link className="dropdown-item" to={"/"}>
                                                                            <span className="mr-2"><i className="fa fa-sign-out"></i></span> Sign Out
                                                                        </Link>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </li>
                                                :
                                                <li className="nav-item">
                                                    <Link className="nav-link active" aria-current="page" to="/login">Login</Link>
                                                </li>
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </nav>
                </header>
                {this.pageRedirectToHome()}
            </Fragment >
        );
    }
}

export default MainHeader;
