import React, { Component } from 'react';
import MainHeader from '../components/common/MainHeader';
import MainFooter from '../components/common/MainFooter';
import Terms from '../components/other/Terms';

class TermsPage extends Component {
    render() {
        return (
            <>
                <MainHeader />

                <Terms />

                <MainFooter />
            </>
        );
    }
}

export default TermsPage;