import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import cogoToast from "cogo-toast";
import LocalStorageHelper from "./LocalStorageHelper/LocalStorageHelper";
import ApiURL from "./api/ApiURL";

function HandleQueryParams() {
  const location = useLocation();
  /* const [queryParams, setQueryParams] = useState({});
  const [error, setError] = useState(null); */

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const paramsObject = {};

    // Convert query params into an object
    for (const [key, value] of searchParams.entries()) {
      paramsObject[key] = value;
    }

    // Save partner details locally if needed
    if (paramsObject["pcode"]) {
      getPartnerDetail(paramsObject["pcode"]);
    }

    //setQueryParams(paramsObject);
  }, [location.search]);

  const getPartnerDetail = async (pcodeval) => {
    //setError(null); // Reset error state before API call
    const requestPayload = { pcode: pcodeval };

    try {
      const res = await fetch(ApiURL.GetPartnerDetails, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(requestPayload),
      });

      if (!res.ok) {
        throw new Error(`Failed to fetch data: ${res.statusText}`);
      }

      const respData = await res.json();
      if (respData.StatusCode === 200) {
        const dataResult = respData.DataResult;
        // Perform additional actions with dataResult if needed
        console.log("Partner Details:", dataResult);
        LocalStorageHelper.setPartner(JSON.stringify(dataResult));
      } else if (respData.StatusCode === 500) {
        cogoToast.error("Internal Server Error! Please Try Again.", { position: "bottom-center" });
      } else {        
        cogoToast.error("Unexpected error occurred. Please try again.", { position: "bottom-center" });
      }
    } catch (err) {
      cogoToast.error("Request Failed! Please Try Again.", { position: "bottom-center" });
      //setError(err.message);
    }
  };

  return (
    <>
      {/* <h1>Query Parameters:</h1>
      <pre>{JSON.stringify(queryParams, null, 2)}</pre>

      {error && <p style={{ color: "red" }}>Error: {error}</p>} */}
    </>
  );
}

export default HandleQueryParams;
