import React, { Component, Fragment } from "react";
import MainHeader from "../components/common/MainHeader";
import HomeBanner from "../components/home/HomeBanner";
import HomeAboutUs from "../components/home/HomeAboutUs";
import OurVehicles from "../components/home/OurVehicles";
import Testimonial from "../components/home/Testimonial";
import LocationMap from "../components/home/LocationMap";
import MainFooter from "../components/common/MainFooter";
import HowToBook from "../components/home/HowToBook";
import WhyChooseUs from "../components/home/WhyChooseUs";

class HomePage extends Component {  
  componentDidMount() {
    window.scroll(0, 0);    
  } 

  render() {
    return (
      <Fragment>

        <MainHeader />   

        <HomeBanner />
        <HomeAboutUs />
        <HowToBook />
        <WhyChooseUs />
        <OurVehicles/>
        <Testimonial/>
        <LocationMap />
        
        <MainFooter />

      </Fragment>
    );
  }
}

export default HomePage;
