import React, { useState, useEffect } from 'react';
import StripeCheckout from 'react-stripe-checkout';
import LocalStorageHelper from '../../LocalStorageHelper/LocalStorageHelper';

const StripeCheckoutButton = ({ service, disabled, paymentByStripe, stripeKey }) => {
    //const [paidFor, setPaidFor] = useState(false);
    const [userData, setUserData] = useState({
        custUserId: null,
        userId: null,
        email: null,
        mobile: null
    });

    // Assuming service contains price in pounds and a description
    const serviceDescription = service?.description || "Airport Cab Fare"; 
    const amount = service?.price || 150; // Default price of £1.50 if not provided
    const currency= "GBP";

    useEffect(() => {
        // Get User Profile Data
        const storedUserData = LocalStorageHelper.getUser();
        try {
            const parsedUserData = JSON.parse(storedUserData);
            if (parsedUserData !== null) {
                setUserData({
                    custUserId: parsedUserData.UserId,
                    userId: parsedUserData.UserId,
                    email: parsedUserData.Email,
                    mobile: parsedUserData.Mobile
                });
            }
        } catch (error) {
            console.error('Error parsing user data:', error);
        }
    }, []);

    return (
        <div className="stripe-button-container">
            <StripeCheckout
                disabled={disabled} 
                token={(token) => {
                    //console.log(token);
                    if (token !== null) {
                        paymentByStripe(token);
                    }
                }}               
                name="Airport Cab - MyBoogy"
                description={serviceDescription}
                email={userData.email}
                panelLabel="Pay"
                currency={currency}
                amount={amount * 100}  // Stripe expects amount in pence
                stripeKey={stripeKey}
            />
        </div>
    );
};

export default StripeCheckoutButton;
