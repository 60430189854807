import React, { Component } from 'react';
import aboutImage from '../../assets/images/about.png';

class HomeAboutUs extends Component {
    render() {
        return (
            <>
                <section className="section_all">
                    <div className="container">
                        <div className="row vertical_content_manage mt-5">
                            <div className="col-lg-6">
                                <div className="about_header_main mt-3">
                                    <div className="titlepage">
                                        <h2>About us</h2>
                                    </div>
                                    <h4 className="about_heading text-capitalize font-weight-bold mt-4">
                                        MyBoogy Airport Transfer offers the lowest fare airport pick-ups and drops in the UK.
                                    </h4>
                                    <p className="text-muted mt-3">
                                        MyBoogy, is a company registered in the UK (VAT NO: 409832974 , UK Company
                                        Number:13867775, TFL LISENSE NO : 011005). MyBoogy Airport Cab has its own
                                        fleet of cars and collaboration with various cab operators all over the country to offer a
                                        seamless airport transfer experience to the customers . Passengers from all over the
                                        globe can book a cab using our platform, to experience a hassle-free and timely ride to
                                        the destination.
                                    </p>
                                    <p>
                                        The cars are operated by licensed operators, and passengers and vehicles are fully
                                        insured. Go for instant quotations and find out the lowest fares from your location to the
                                        airport &amp; vice-versa.
                                    </p>
                                    <p className="text-muted mt-3">
                                        We, MyBoogy Airport Transfer accept all major credit/ debit cards and other leading payment methods.
                                    </p>
                                </div>                                
                            </div>
                            <div className="col-lg-6">
                                <div className="img_about mt-3">
                                    <img src={aboutImage} alt="..." className="img-fluid mx-auto d-block" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default HomeAboutUs;