import React, { Component } from 'react';
import whychooseusImage from '../../assets/images/whychooseus.jpg';

class WhyChooseUs extends Component {
    render() {
        return (
            <>
                <section className="section_all">
                    <div className="container">
                        <div className="row vertical_content_manage mt-5">
                            <div className="col-lg-6">
                                <div className="titlepage">
                                    <h2>Why Choose Us?</h2>
                                </div>
                                <p className="text-muted mt-3">
                                    <ul className='list-style'>
                                        <li><strong>Personalized Service:</strong> From the moment you contact us, our team is<br />Committed to offering you a smooth and hassle-free experience.</li>                                        
                                        <li><strong>Professional Drivers:</strong> Our experienced drivers, with excellent local<br />knowledge and customer service skills will ensure a safe and comfortable<br />journey.</li>                                        
                                        <li> <strong>Modern Fleet:</strong> Choose from a variety of well-maintained, air-conditioned<br />vehicles, including saloon cars (sedans), estate cars (station wagons),<br />people carriers (MPV s), minibusses, and executive cars.</li>
                                    </ul>
                                </p>
                                <br/>
                                <p>
                                    <strong>Serving a Wide Range of Clients</strong><br />
                                    We proudly provide top-notch airport transfer services to local and international<br />
                                    travellers, businesses of all sizes, hotels, and airlines. Our customers trust us for our<br />
                                    reliability, professionalism, Punctuality and high-quality service, even on short notice.
                                </p>
                                <br/>
                                <p>
                                    <strong>Book Your Ride Today</strong><br />
                                    Whether you’re a frequent traveller or planning a one-time trip, we’re here to make your
                                    Airport transfer is easy and enjoyable. Book your Airport cab with MyBoogy Airport
                                    Transfer now and experience the comfort and convenience of travelling with us!
                                </p>
                            </div>
                            <div className="col-lg-6">
                                <div className="img_about mt-3">
                                    <img src={whychooseusImage} alt="..." className="img-fluid mx-auto d-block" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default WhyChooseUs;