import React, { Component } from 'react';
import mapImage from '../../assets/images/map.png';

class LocationMap extends Component {
    render() {
        return (
            <>
                <div className="footer bottom_cross1">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4">
                                <ul className="location_icon">
                                    <li><a href="#"><i className="fa fa-map-marker" aria-hidden="true"></i></a>
                                        450 Bath Road, <br />
                                        London UB7 0EB, <br />
                                        United Kingdom. <br />
                                    </li>
                                    <li><a href="#"><i className="fa fa-phone" aria-hidden="true"></i></a>Phone :  +44(0)331-630-4110</li>
                                    <li><a href="#"><i className="fa fa-envelope" aria-hidden="true"></i></a>Email : contact-us@myboogy.co.uk</li>
                                </ul>
                            </div>
                            <div className="col-md-8">
                                <div className="map">
                                    <figure><img src={mapImage} alt="..." /></figure>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default LocationMap;