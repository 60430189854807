import React, { Component } from 'react';
import howtobookImage from '../../assets/images/howtobook.jpg';

class HowToBook extends Component {
    render() {
        return (
            <>
                <section className="section_all">
                    <div className="container">
                        <div className="row vertical_content_manage mt-5">
                            <div className="col-lg-6">
                                <div className="img_about mt-3">
                                    <img src={howtobookImage} alt="..." className="img-fluid mx-auto d-block" />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="about_header_main mt-3">
                                    <div className="titlepage">
                                        <h2>How to Book Your Cab at MyBoogy</h2>
                                    </div>
                                    <h4 className="about_heading text-capitalize font-weight-bold mt-4">
                                        MyBoogy Airport Transfer offers the lowest fare airport pick-ups and drops in the UK.
                                    </h4>
                                    <p className="text-muted mt-3">
                                        <ul className='list-style'>
                                            <li>
                                                <b>Get an Instant Quote:</b> Visit our website to get quotation in &#39;Three Click
                                                Quote&#39; form. All you need to do is to select an Airport Terminal from the
                                                drop down &amp; provide a UK postcode as a pick-up/ drop-off location and
                                                proceed.
                                            </li>
                                            <li>
                                                <b>Choose Your Vehicle:</b> Click &#39;Quote Now&#39; and check out all the vehicle
                                                options that you have. Offering best rates for cabs in UK is our promise.
                                                Book one-sided or return journeys at discounted price.
                                            </li>
                                            <li>
                                                <b>Confirm Your Booking:</b> On completion of the booking, an email will be
                                                sent confirming your reserved journey.
                                            </li>
                                            <li>
                                                <b>Baby Seats/ Meet and Greet:</b> We offer baby seats on request at the time
                                                of booking and also can offer meet and greet service.
                                            </li>
                                        </ul>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default HowToBook;